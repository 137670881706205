import { useEffect } from 'react'

export const toggleCalendar = (e, setIsCalendarOpen) => {
  e.stopPropagation()
  setIsCalendarOpen(prev => !prev)
}

export const useOutsideClickHandler = (isCalendarOpen, setIsCalendarOpen, calendarRef, calendarButtonRef) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isCalendarOpen &&
            calendarRef.current &&
            !calendarRef.current.contains(event.target) &&
            !calendarButtonRef.current
      ) {
        setIsCalendarOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isCalendarOpen, calendarRef, calendarButtonRef])
}
