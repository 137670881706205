import React from 'react'
import { SearchIcon } from '../../img/SearchIcon'

export default function SearchBar ({ onChange, value }) {
  return (
    <div className="relative">
      {/* Left icon */}
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <SearchIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      </div>

      {/* The input. Notice the pl-9 / pr-9 to carve out space for icon + shortcut */}
      <input
        id="search"
        name="search"
        type="text"
        placeholder="Search"
        value={value}
        onChange={onChange}
        className="
          block w-full
          rounded-md border-0 py-2
          pl-10 pr-9
          text-gray-900 text-sm shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400
          focus:ring-2 focus:ring-inset focus:ring-indigo-600
          sm:text-sm leading-tight
        "
      />

      {/* Right shortcut */}
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
          ⌘K
        </kbd>
      </div>
    </div>
  )
}
