// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'//
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAE5FA_qmfSjy9eT39b-hhZriimlMpufgc',
  authDomain: 'web-accessibility-71b5d.firebaseapp.com',
  projectId: 'web-accessibility-71b5d',
  storageBucket: 'web-accessibility-71b5d.appspot.com',
  messagingSenderId: '948064088090',
  appId: '1:948064088090:web:bfd12057f42f96a13ca1a3',
  measurementId: 'G-E8BG4CLDGG',
  databaseURL: 'https://web-accessibility-71b5d-default-rtdb.europe-west1.firebasedatabase.app/'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app);

// Inicializa Firestore
const database = getDatabase(app)
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)
export { database, db, storage, auth }
