export const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'Sin fecha'
  if (timestamp.toDate) {
    return new Date(timestamp.toDate()).toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }
  return 'Sin fecha'
}

export function areDatesEqual (d1, d2) {
  if (!d1 || !d2) return false

  return d1.getFullYear() === d2.getFullYear() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getDate() === d2.getDate()
}

export const formatDateToDDMMYYYY = (date) => {
  if (!date) return ''
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

function formatLocalYyyyMmDd (date) {
  const yyyy = date.getFullYear()
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return `${yyyy}-${mm}-${dd}`
}

export const parseDDMMYYYY = (str) => {
  const [day, month, year] = str.split('/')
  if (!day || !month || !year) return null
  return new Date(year, month - 1, day)
}

export const dateOptions = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Last month',
  'This year',
  'Last year',
  'All time'
]

export function handleDateChange (dateOption, setStartDate, setEndDate) {
  switch (dateOption) {
    case 'Today':
      setStartDate(new Date())
      setEndDate(new Date())
      break
    case 'Yesterday':
      {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        setStartDate(yesterday)
        setEndDate(yesterday)
      }
      break
    case 'This week':
      {
        const now = new Date()
        // Get the Monday of the current week (assuming Monday start)
        const weekStart = new Date(now)
        const day = weekStart.getDay() // Sunday=0, Monday=1, ...
        const mondayIndex = (day === 0) ? -6 : (1 - day)
        weekStart.setDate(weekStart.getDate() + mondayIndex)
        // Get the Saturday of that same week
        const weekEnd = new Date(weekStart)
        weekEnd.setDate(weekEnd.getDate() + 6)

        setStartDate(weekStart)
        setEndDate(weekEnd)
      }
      break
    case 'Last week':
      {
        const now = new Date()
        const weekStart = new Date(now)
        const day = weekStart.getDay() // Sunday=0, Monday=1, ...
        const mondayIndex = (day === 0) ? -6 : (1 - day)

        // Move to this week's Monday
        weekStart.setDate(weekStart.getDate() + mondayIndex)

        // Now go back one full week to get last week's Monday
        weekStart.setDate(weekStart.getDate() - 7)

        // Create a new Date for weekEnd based on weekStart
        const weekEnd = new Date(weekStart)
        weekEnd.setDate(weekEnd.getDate() + 6)

        setStartDate(weekStart)
        setEndDate(weekEnd)
      }
      break
    case 'This month': {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
      const monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      setStartDate(monthStart)
      setEndDate(monthEnd)
    }
      break
    case 'Last month': {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)

      monthStart.setMonth(monthStart.getMonth() - 1)
      const monthEnd = new Date(now.getFullYear(), monthStart.getMonth() + 1, 0)

      setStartDate(monthStart)
      setEndDate(monthEnd)
    }
      break
    case 'This year': {
      const now = new Date()
      const yearStart = new Date(now.getFullYear(), 0, 1) // January 1st of the current year
      const yearEnd = new Date(now.getFullYear(), 11, 31) // December 31st of the current year

      setStartDate(yearStart)
      setEndDate(yearEnd)
    }
      break
    case 'Last year': {
      const now = new Date()
      const yearStart = new Date(now.getFullYear() - 1, 0, 1) // January 1st of last year
      const yearEnd = new Date(now.getFullYear() - 1, 11, 31) // December 31st of last year

      setStartDate(yearStart)
      setEndDate(yearEnd)
    }
      break
    case 'All time': {
      const earliestDate = new Date(0) // Unix epoch start
      const now = new Date()

      setStartDate(earliestDate)
      setEndDate(now)
    }
      break
    default: {
      const earliestDate = new Date(0) // Unix epoch start
      const now = new Date()

      setStartDate(earliestDate)
      setEndDate(now)
    }
      break
  }
}

function isLocalToday (date) {
  const now = new Date()
  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  )
}

// SUGGESTED FIX
export const getDaysInMonth = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth()

  console.log('date', date)

  // We'll create a Date for the 1st of the month:
  const firstDay = new Date(year, month, 1)

  // JavaScript's getDay() => 0 (Sun), 1 (Mon), 2 (Tue)...
  // We want Monday=0, Tuesday=1, ..., Sunday=6.
  // A quick trick is: (jsDay + 6) % 7
  //   - If jsDay=0 (Sunday), => (0+6)%7=6 => Sunday=6
  //   - If jsDay=1 (Monday), => (1+6)%7=0 => Monday=0
  //   - etc.
  const firstDayJsIndex = firstDay.getDay() // 0..6
  const firstDayOfWeek = (firstDayJsIndex + 6) % 7 // shift so Monday=0

  console.log('firstDayOfWeek', firstDayOfWeek)

  // We want a total of 6 rows × 7 days = 42 days in the grid
  const totalCells = 42

  // How many days in THIS month
  const lastDayOfMonth = new Date(year, month + 1, 0) // e.g. Jan 31, Feb 28, etc.
  const daysInThisMonth = lastDayOfMonth.getDate() // 28..31

  // We'll build an array of { date, isCurrentMonth, isToday }
  const days = []

  // 1) Fill days from the previous month if needed
  //    e.g. if firstDayOfWeek=0 => means Monday is the 1st, no prev month days
  //    if firstDayOfWeek=3 => means the 1st was a Thursday, so we show 3 prev days
  if (firstDayOfWeek > 0) {
    // The last day in the previous month:
    const lastDayOfPrevMonth = new Date(year, month, 0).getDate() // e.g. 31 if month=1

    // How many "leading" days to show:
    for (let i = 0; i < firstDayOfWeek; i++) {
      console.log('firstDayOfWeek', firstDayOfWeek, firstDayJsIndex, lastDayOfPrevMonth, i)
      const dayNum = lastDayOfPrevMonth - (firstDayOfWeek - 1) + i

      console.log('dayNum', firstDayOfWeek - 1, i, dayNum)
      const d = new Date(year, month - 1, dayNum)

      console.log('d in prevMonth', d, dayNum, d.toISOString().split('T')[0])
      days.push({
        date: formatLocalYyyyMmDd(d),
        isCurrentMonth: false,
        isToday: false
      })
    }
  }

  // 2) Fill the current month's days
  for (let i = 1; i <= daysInThisMonth; i++) {
    const d = new Date(year, month, i)
    console.log('d in currentMonth', d, i)
    days.push({
      date: formatLocalYyyyMmDd(d),
      isCurrentMonth: true,
      isToday: isLocalToday(d)
    })
  }

  // 3) Fill the trailing days from the next month
  //    until we have exactly 42 days total
  const remainingCells = totalCells - days.length
  for (let i = 1; i <= remainingCells; i++) {
    const d = new Date(year, month + 1, i)
    days.push({
      date: formatLocalYyyyMmDd(d),
      isToday: false
    })
  }

  console.log('days', days)

  return days
}

export const daysInWeek = [
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
  'Su'
]
