import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import JSZip from 'jszip'
import './createReport.css'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

const UploadComponent = ({ onUpload }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('')

  const extractNameFromPath = (path, fetchTime) => {
    // Dividir la ruta por '/' y filtrar las partes vacías
    const parts = path.split('/').filter(part => part)

    console.log('parts', parts)

    // Si la ruta es muy corta, usar una versión sanitizada de la URL entera
    if (parts.length === 0) {
      return 'Unknown_Page'
    }

    let significantPart = ''

    // Obtener las dos últimas partes de la ruta, o una si solo hay una disponible
    if (parts.length >= 2) {
      significantPart = parts.slice(parts.length - 2).join('/') // Concatenar las dos últimas partes con '/'
    } else {
      significantPart = parts[0] // Si solo hay una parte, usar esa
    }

    // Verificar si fetchTime está definido y no es nulo
    if (!fetchTime || typeof fetchTime !== 'string') {
      throw new Error(`Invalid fetchTime: ${fetchTime}`)
    }

    // Intentar convertir fetchTime en un objeto de fecha
    const date = new Date(fetchTime)
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date format in fetchTime: ${fetchTime}`)
    }

    // Obtener la fecha en formato ISO 8601
    const isoDate = date.toISOString()

    // Si no se encuentra una parte significativa, usar la última parte de la URL
    if (!significantPart) {
      significantPart = parts[parts.length - 1]
    }

    // Sanitizar la parte significativa eliminando caracteres no permitidos y formatear el nombre
    const sanitizedPart = significantPart
      .replace(/[^a-zA-Z0-9]/g, ' ') // Reemplazar caracteres no alfanuméricos por espacios
      .split(' ') // Dividir en palabras
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizar las palabras
      .join(' ') // Unir las palabras de nuevo
      .substring(0, 50) // Limitar la longitud del nombre a 50 caracteres si es necesario

    return `${sanitizedPart}_${isoDate}`
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true)
    setMessage('')

    const jsonFiles = []

    for (const file of acceptedFiles) {
      if (file.name.endsWith('.zip')) {
        console.log(`Processing ZIP file: ${file.name}`)
        const zip = new JSZip()
        try {
          const content = await zip.loadAsync(file)

          for (const relativePath in content.files) {
            if (relativePath.endsWith('.json')) {
              console.log(`Extracting JSON from ZIP: ${relativePath}`)
              const fileContent = await content.files[relativePath].async('string')
              const parsedContent = JSON.parse(fileContent)
              const finalDisplayedUrl = parsedContent.finalDisplayedUrl
              const fetchTime = parsedContent.fetchTime

              const webPath = new URL(finalDisplayedUrl).pathname + new URL(finalDisplayedUrl).hash
              const name = extractNameFromPath(webPath, fetchTime) // Nombre único basado en la URL
              const description = 'Desktop Data'

              jsonFiles.push({
                name,
                content: new Blob([fileContent], { type: 'application/json' }),
                webPath,
                description
              })
              console.log(`Processed JSON file: ${name}, webPath: ${webPath}`)
            }
          }
        } catch (error) {
          console.error('Error extracting zip file: ', error)
          setMessage('Failed to upload files.')
          setMessageType('error')
        }
      } else if (file.name.endsWith('.json')) {
        console.log(`Processing JSON file: ${file.name}`)
        try {
          const fileContent = await file.text()
          const parsedContent = JSON.parse(fileContent)
          const finalDisplayedUrl = parsedContent.finalDisplayedUrl
          const fetchTime = parsedContent.fetchTime

          const webPath = new URL(finalDisplayedUrl).pathname + new URL(finalDisplayedUrl).hash
          const name = extractNameFromPath(webPath, fetchTime) // Nombre único basado en la URL
          const description = 'Desktop Data'

          jsonFiles.push({
            name,
            content: new Blob([fileContent], { type: 'application/json' }),
            webPath,
            description
          })
          console.log(`Processed JSON file: ${name}, webPath: ${webPath}`)
        } catch (error) {
          console.error('Error reading JSON file: ', error)
          setMessage('Failed to parse JSON file.')
          setMessageType('error')
        }
      } else {
        console.warn(`Unsupported file type: ${file.name}`)
        setMessage('Unsupported file type.')
        setMessageType('error')
      }
    }

    setLoading(false)
    if (jsonFiles.length > 0) {
      console.log(`Successfully processed ${jsonFiles.length} files`)
      setMessage('Files uploaded successfully.')
      setMessageType('success')
      onUpload(jsonFiles)
    } else {
      console.warn('No valid files were processed')
      setMessage('No valid files were processed.')
      setMessageType('error')
    }
  }, [onUpload])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div className="sm:grid sm:grid-cols-3 border-t border-b border-gray-900/10 sm:items-start sm:gap-4 py-5">
      <label className="label-required block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
        Json
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <div
          {...getRootProps()}
          className="block w-full rounded-md bg-white px-6 py-4 text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 hover:border-purple-600 focus:outline-2 focus:-outline-offset-2 focus:outline-purple-600 sm:max-w-md sm:text-sm/6 transition-colors"
        >
          <input {...getInputProps()} />
          <div className="border-2 border-gray-300 p-1 flex justify-center items-center w-10 h-10 rounded-t-xl rounded-b-xl mx-auto">
            <CloudArrowUpIcon className="w-8 h-8 text-gray-500" />
          </div>
          <p className="text-text-violet text-sm/6 font-bold text-center">Click to upload or drag and drop</p>
          <p className="text-gray-500 text-sm/6 text-center">JSON files</p>
          {loading && <p>Processing files...</p>}
          {message && (
            <p className={`mt-2 ${messageType === 'error' ? 'text-red-500' : 'text-green-500'}`}>
              {message}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

UploadComponent.propTypes = {
  onUpload: PropTypes.func.isRequired
}

export default UploadComponent
