import React from 'react'

export const ChecksIcon = () => {
  return (

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="check-done-01" clipPath="url(#clip0_1431_881)">
<path id="Icon" d="M5.00008 12.5L6.66675 14.1667L10.4167 10.4167M6.66675 6.66667V4.33334C6.66675 3.39992 6.66675 2.93321 6.8484 2.57669C7.00819 2.26308 7.26316 2.00812 7.57676 1.84833C7.93328 1.66667 8.39999 1.66667 9.33341 1.66667H15.6667C16.6002 1.66667 17.0669 1.66667 17.4234 1.84833C17.737 2.00812 17.992 2.26308 18.1518 2.57669C18.3334 2.93321 18.3334 3.39992 18.3334 4.33334V10.6667C18.3334 11.6001 18.3334 12.0668 18.1518 12.4233C17.992 12.7369 17.737 12.9919 17.4234 13.1517C17.0669 13.3333 16.6002 13.3333 15.6667 13.3333H13.3334M4.33341 18.3333H10.6667C11.6002 18.3333 12.0669 18.3333 12.4234 18.1517C12.737 17.9919 12.992 17.7369 13.1518 17.4233C13.3334 17.0668 13.3334 16.6001 13.3334 15.6667V9.33334C13.3334 8.39992 13.3334 7.93321 13.1518 7.57669C12.992 7.26308 12.737 7.00812 12.4234 6.84833C12.0669 6.66667 11.6002 6.66667 10.6667 6.66667H4.33341C3.39999 6.66667 2.93328 6.66667 2.57676 6.84833C2.26316 7.00812 2.00819 7.26308 1.8484 7.57669C1.66675 7.93321 1.66675 8.39992 1.66675 9.33334V15.6667C1.66675 16.6001 1.66675 17.0668 1.8484 17.4233C2.00819 17.7369 2.26316 17.9919 2.57676 18.1517C2.93328 18.3333 3.39999 18.3333 4.33341 18.3333Z" stroke="#717680" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1431_881">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}
