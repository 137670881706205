import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default function PasswordPage () {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleResetPassword = async (e) => {
    e.preventDefault()
    const auth = getAuth()

    try {
      await sendPasswordResetEmail(auth, email)
      setMessage('Se ha enviado un correo para restablecer tu contraseña')
      setError('')
    } catch (error) {
      setError('Error al enviar el correo. Verifica la dirección')
      setMessage('')
    }
  }

  return (
    <div className='container mx-auto flex flex-col justify-center items-center h-screen -mt-20'>
            <div className="text-center mb-6">
                <img src="https://tailwindui.com/plus/img/logos/mark.svg?color=violet&shade=600" alt="" className="mx-auto w-16 mb-4" />
                <h1 className="text-2xl font-bold">Reset password</h1>
            </div>

            <div className="mb-6 flex flex-col items-center">
                <label className="text-gray-700 mb-2 text-left w-[360px]">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="usuario@ejemplo.com"
                    className="p-2 border rounded-md w-[360px]"
                />
                {message && <p className="text-sm text-green-600 mt-1 w-[360px]">{message}</p>}
                {error && <p className="text-sm text-red-600 mt-1 w-[360px]">{error}</p>}
                <span className="text-sm text-gray-600 mt-1 w-[360px]">Requires your email address.</span>
            </div>
            <div className="flex justify-center -mt-2">
              <button
                onClick={handleResetPassword}
                className="flex flex-col w-[360px] bg-violet-600 font-semibold text-white py-2 shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 rounded-md mb-4 items-center justify-center"
              >
                  Reset password
              </button>
            </div>
            <div className="text-center">
                <span className="text-gray-600">Already have an account? </span>
                <Link to="/login" className="text-violet-600">Sign in</Link>
            </div>
    </div>
  )
}
