/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CalendarIcon } from '../img/CalendarIcon'
import CalendarCard from '../components/common/CalendarCard'
import { useListData } from '../hooks/useProjects'
import Pagination from '../components/common/Pagination'
import ListComponent from '../components/lists/ListComponent'
import Button from '../components/common/Button'
import SearchBar from '../components/common/SearchBar'
import { useOutsideClickHandler, toggleCalendar } from '../utils/calendarUtils'

const ListPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortField, setSortField] = useState('name')
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const { listType } = useParams()
  // store a Date in state rather than a number
  const [startDate, setStartDate] = useState(() => {
    const d = new Date(0)
    d.setHours(0, 0, 0, 0)
    return d
  })
  const [endDate, setEndDate] = useState(() => {
    const d = new Date()
    d.setHours(23, 59, 59, 999)
    return d
  })
  const calendarRef = useRef(null)
  const calendarButtonRef = useRef(null)

  // Data from your custom hooks. If they're null before loading, default to []:
  const rawData = useListData(sortField, sortOrder)
  const data = rawData || []

  // Re-compute filteredData whenever data or filters change
  const filteredData = useMemo(() => {
    return data
      .filter((item) => {
      // Filter by date range if timestamp exists
        if (item.timestamp?.toDate) {
          const itemDate = item.timestamp.toDate()
          const itemDateNoTime = new Date(itemDate)
          const startDateNoTime = new Date(startDate)
          const endDateNoTime = new Date(endDate)
          startDateNoTime.setHours(0, 0, 0, 0)
          endDateNoTime.setHours(23, 59, 59, 999)
          itemDateNoTime.setHours(0, 0, 0, 0) // zero out the time

          return itemDateNoTime >= startDateNoTime && itemDateNoTime <= endDateNoTime
        }
        return true
      })
      .filter((item) => {
      // Filter by search term
        const name = item.name?.toLowerCase() || ''
        const scope = item.scope?.toLowerCase() || ''
        const search = searchTerm.toLowerCase()
        return name.includes(search) || scope.includes(search)
      })
  }, [data, startDate, endDate, searchTerm])

  console.log('in ListPage', startDate, endDate)

  useOutsideClickHandler(isCalendarOpen, setIsCalendarOpen, calendarRef, calendarButtonRef)

  return (
    <>
      <div className="pb-4">
        <div className="flex items-center justify-between">
          <div className="relative">
            <SearchBar value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>

          {/* Buttons */}
          <div className="relative">
            <div className="flex items-center gap-2">
              {/* "Select dates" Button */}
              <Button
                logo={<CalendarIcon className="h-5 w-5" />}
                text="Select dates"
                className="logo-button"
                onClick={(e) => toggleCalendar(e, setIsCalendarOpen)}
                ref={calendarButtonRef}
              />
            </div>

            {/* Calendar modal */}
            {isCalendarOpen && (
              <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 shadow-lg rounded-lg z-50"
                ref={calendarRef}>
                <CalendarCard startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                  isCalendarOpen={isCalendarOpen} setIsCalendarOpen={setIsCalendarOpen} />
              </div>
            )}
          </div>
        </div>
      </div>
      <ListComponent
        projects={filteredData}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        sortOrder={sortOrder}
        sortField={sortField}
        listType={listType}
        onSortChange={(field, order) => {
          setSortField(field)
          setSortOrder(order)
        }}
        footer={
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredData}
          />
        }
      />
    </>
  )
}

export default ListPage
