import React from 'react'
import { classNames } from '../../utils/classNames'

export default function Button ({ logo, text, className, onClick, logoPosition = 'left', disabled = false }) {
  const content = logoPosition === 'right'
    ? (
      <>
        <span className="leading-none px-0.5">{text}</span>
        {logo && <span>{logo}</span>}
      </>
      )
    : (
      <>
        {logo && <span>{logo}</span>}
        <span className="leading-none px-0.5">{text}</span>
      </>
      )
  return (
    <button
      onClick={onClick}
      className={classNames('bar flex items-center justify-center px-3.5 py-2.5 text-sm rounded-lg font-semibold ', className)}
      disabled={disabled}
    >
      <span className="flex items-center justify-center gap-1">
        {content}
      </span>
    </button>
  )
}
