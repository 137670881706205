import React, { useState, useEffect } from 'react'
import {
  ChevronUpDownIcon
} from '@heroicons/react/24/outline'

import { ChecksIcon } from '../../../img/ChecksIcon'
import { HomeIcon } from '../../../img/HomeIcon'
import { RowsIcon } from '../../../img/RowsIcon'
import { LogoImg } from '../../../img/LogoImg'
import { auth, database } from '../../../firebase'
import { ref, onValue, off } from 'firebase/database'
import SearchBar from '../../common/SearchBar'
import { Link, useLocation } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Projects', href: '/list/projects', icon: RowsIcon },
  { name: 'Tasks', href: '/list/tasks', icon: ChecksIcon }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar () {
  const location = useLocation()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userRef = ref(database, `users/${auth.currentUser.uid}`)
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            setUserData(snapshot.val())
          }
        })
      }
    }

    fetchUserData()

    return () => {
      if (auth.currentUser) {
        const userRef = ref(database, `users/${auth.currentUser.uid}`)
        off(userRef)
      }
    }
  }, [])

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
            <div className="flex flex-col px-5 pt-6 gap-y-5">
              <LogoImg className="shrink-0"/>
            <SearchBar />
            </div>
            <nav className="flex flex-1 flex-col px-4">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="space-y-1">
                    {navigation.map((item) => {
                      const isCurrent = item.href === location.pathname
                      return (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              isCurrent
                                ? 'bg-gray-50 text-text-violet'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-text-violet',
                              'group flex gap-x-2.5 h-10 rounded-md p-2 text-sm font-semibold'
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={classNames(
                                isCurrent
                                  ? 'text-text-violet'
                                  : 'text-gray-400 group-hover:text-text-violet',
                                'h-5 w-5 shrink-0'
                              )}
                            />
                            {item.name}
                          </Link>
                        </li>
                      )
                    })}

                  </ul>
                </li>
                {/* Profile */}
                <li className="mt-auto">
                  <a
                    onClick={() => auth.signOut()}
                    href="/login"
                    className="relative flex items-center rounded-lg border border-gray-200 p-3 bg-white mb-6 shadow-sm hover:bg-gray-50"
                  >
                    {/* Avatar */}
                    <img
                      alt=""
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      className="h-10 w-10 rounded-full"
                    />
                    {/* Profile Info */}
                    <div className="flex flex-col pl-2">
                      <span aria-hidden="true" className="text-sm font-semibold text-gray-900">
                        {userData?.name} {userData?.lastname}
                      </span>
                      <span className="text-sm text-gray-500">{auth.currentUser?.email}</span>
                    </div>
                    {/* Chevron Icon */}
                    <ChevronUpDownIcon
                      className="absolute top-1 right-1 h-8 p-1.5 text-gray-400"
                      aria-hidden="true"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
