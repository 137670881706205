import React, { useState, useEffect } from 'react'
import { auth } from '../firebase'
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence, onAuthStateChanged } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'

export default function LoginPage () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [setError] = useState('')
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && rememberMe) {
        localStorage.setItem('authTimestamp', Date.now().toString())
      }
    })

    return () => unsubscribe()
  }, [rememberMe])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const authTimestamp = localStorage.getItem('authTimestamp')
      if (authTimestamp) {
        const daysPassed = (Date.now() - parseInt(authTimestamp)) / (1000 * 60 * 60 * 24)
        if (daysPassed > 30) {
          await auth.signOut()
          localStorage.removeItem('authTimestamp')
          return
        }
      }

      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence)
      await signInWithEmailAndPassword(auth, email, password)
      navigate('/')
    } catch (error) {
      setError(error.message)
    }
  }
  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex flex-col w-[576px]">
        <div className="flex-1 flex flex-col items-center justify-center">
          <div className="w-full max-w-[360px]">
            <div>
              <img
                src="logo.svg"
                alt="Logo"
                className="h-10 w-auto mb-12"
              />
            </div>

            <div className="flex-1">
              <h1 className="text-[36px] font-semibold mb-2">Log in</h1>
              <p className="text-[16px] text-gray-500 mb-8">Welcome back! Please enter your details.</p>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm mb-2 font-weight-[400]">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full h-[44px] p-3 border border-gray-200 rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm mb-2 font-weight-[400]">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="••••••••"
                    className="w-full h-[44px] p-3 border border-gray-200 rounded-lg"
                  />
                </div>

                <div className="flex items-center justify-between min-h-[20px]">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="mr-2"
                    />
                    <span className="text-sm">Remember for 30 days</span>
                  </div>
                  <Link to="/password" className="text-text-violet font-semibold text-sm">
                    Forgot password
                  </Link>
                </div>

                <button
                  type="submit"
                  className="w-full bg-violet-600 text-white py-3 rounded-lg h-[44px]"
                >
                  Sign in
                </button>
              </form>

              <p className="text-center mt-16 min-h-[20px]">
                Don&apos;t have an account?{' '}
                <Link to="/signup" className="text-text-violet font-semibold ">
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="h-24 flex items-center">
          <p className="text-[14px] text-[#6B7280] pl-8">© Okb Interactive 2025</p>
        </div>
      </div>

      <div className="flex-1 bg-gray-50 flex flex-col items-center pl-16 pt-16 overflow-hidden">
        <div className="pl-16 flex flex-col items-center max-h-full w-full">
          <div className="w-full flex flex-col items-start">
            <h2 className="text-3xl font-medium mb-8 leading-tight text-justify min-w-[656px] min-h-[156px]">
              Empowering Havas to create more inclusive<br/>
              digital experiences by simplifying<br/>
              accessibility reporting and automating<br/>
              compliance processes with precision.
            </h2>
            <img
              src="Modern screen mockup.png"
              alt="Dashboard preview"
              className="object-cover object-left h-[calc(200vh-900px)]  w-[calc(100vw-640px-32px)] rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
