import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase'

export function useListData (sortField, sortOrder) {
  const [data, setData] = useState([])
  const { listType } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, `accessibility-${listType}`),
        orderBy('config.timestamp', 'desc')
      )
      const querySnapshot = await getDocs(q)

      // If the collection is empty, just set an empty array and return
      if (querySnapshot.empty) {
        setData(null)
        return
      }

      const lists = querySnapshot.docs.map((doc) => {
        const dataDoc = doc.data()
        const config = dataDoc.config || {}
        const timestamp = config.timestamp || { toDate: () => new Date(0) }

        if (listType === 'projects') {
          return {
            id: doc.id,
            name: config.name || 'Unnamed',
            timestamp,
            webpages: config.webpages || [],
            url: config.url || '',
            scope: config.scope || '',
            environment: config.environment
          }
        } else {
          return {
            id: doc.id,
            name: config.name || 'Unnamed',
            timestamp,
            webpages: config.webpages || [],
            url: config.url || '',
            idJira: config.idJira || '',
            environment: config.environment
          }
        }
      })

      // Sort the data (make sure you're sorting the projectsData, not the old state)
      lists.sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        } else if (sortField === 'urls') {
          // 'urls' presumably means the length of the webpages array
          return sortOrder === 'asc'
            ? a.webpages.length - b.webpages.length
            : b.webpages.length - a.webpages.length
        }
        return 0
      })

      setData(lists)
    }

    fetchData()
  }, [sortField, sortOrder, listType])

  return data
}

export function useProjects (sortField, sortOrder) {
  const [projects, setProjects] = useState([])
  const { listType } = useParams()

  useEffect(() => {
    const fetchProjects = async () => {
      const q = query(
        collection(db, 'accessibility-projects'),
        orderBy('config.timestamp', 'desc')
      )
      const querySnapshot = await getDocs(q)
      const projectsData = querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const config = data.config || {}
        const timestamp = config.timestamp || { toDate: () => new Date(0) }
        return {
          id: doc.id,
          name: config.name || 'Unnamed',
          timestamp,
          webpages: config.webpages || [],
          url: config.url || '',
          scope: config.scope || '',
          environment: config.environment
        }
      })

      // Sorting logic
      projectsData.sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        } else if (sortField === 'urls') {
          return sortOrder === 'asc'
            ? a.webpages.length - b.webpages.length
            : b.webpages.length - a.webpages.length
        }
        return 0
      })

      setProjects(projectsData)
    }

    fetchProjects()
  }, [sortField, sortOrder, listType])

  return projects
}

export function useTasks (sortField, sortOrder) {
  const [tasks, setTasks] = useState([])
  const { listType } = useParams()

  useEffect(() => {
    const fetchTasks = async () => {
      const q = query(
        collection(db, 'accessibility-tasks'),
        orderBy('config.timestamp', 'desc')
      )
      const querySnapshot = await getDocs(q)
      const tasksData = querySnapshot.docs.map((doc) => {
        const dataDoc = doc.data()
        const config = dataDoc.config || {}
        const timestamp = config.timestamp || { toDate: () => new Date(0) }
        return {
          id: doc.id,
          name: config.name || 'Unnamed',
          timestamp,
          webpages: config.webpages || [],
          url: config.url || '',
          idJira: config.idJira || '',
          environment: config.environment
        }
      })

      // Sorting logic
      tasksData.sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        } else if (sortField === 'urls') {
          return sortOrder === 'asc'
            ? a.webpages.length - b.webpages.length
            : b.webpages.length - a.webpages.length
        }
        return 0
      })

      setTasks(tasksData)
    }

    fetchTasks()
  }, [sortField, sortOrder, listType])

  return tasks
}
