import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, useNavigate } from 'react-router-dom'
import './styles/globals.css'
import { AuthProvider } from './context/AuthContext'
import ProtectedRoute from './components/ProtectedRoute'
import { auth } from './firebase'
import HomePage from './pages/HomePage'
import AccessibilityData from './pages/AccessibilityData'
import CreateReportPage from './pages/CreateReportPage'
import ListPage from './pages/ListPage'
import PDFPreviewPage from './pages/PDFPreviewPage'
import Sidebar from './components/layout/sidebar/Sidebar'
import LoginPage from './pages/LoginPage'
import PasswordPage from './pages/PasswordPage'
import SignUpPage from './pages/SignUpPage'

const BaseLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const pageName = () => {
    switch (location.pathname) {
      case '/':
        return 'Home'
      case '/list/projects':
        return 'Accessibility Dashboard'
      case '/list/tasks':
        return 'Tasks Dashboard'
      default:
        return 'Document Detail'
    }
  }

  useEffect(() => {
    const checkSessionExpiry = () => {
      const authTimestamp = localStorage.getItem('authTimestamp')
      if (authTimestamp) {
        const daysPassed = (Date.now() - parseInt(authTimestamp)) / (1000 * 60 * 60 * 24)
        if (daysPassed > 30) {
          auth.signOut()
          localStorage.removeItem('authTimestamp')
          navigate('/login')
        }
      }
    }

    checkSessionExpiry()
  }, [])

  return (
    <div>
      <Sidebar />
      <main className="pb-12 lg:pl-64 min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Encabezado */}
          <div className="py-8">
            <h1 className="text-3xl font-bold text-gray-900">{pageName()}</h1>
            <p className="mt-1 text-xl text-gray-600">
              Effortlessly track and analyze web and task accessibility reports
            </p>
          </div>
          <Outlet />
        </div>
      </main>
    </div>
  )
}

function App () {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        {/* Wrap all relevant routes with BaseLayout */}
        <Route path="/" element={
          <ProtectedRoute>
            <BaseLayout />
          </ProtectedRoute>
          }>
          <Route index element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
            } /> {/* Default route */}
          <Route path="create/:listType" element={
            <ProtectedRoute>
              <CreateReportPage />
            </ProtectedRoute>
            } />
          <Route path="data" element={
            <ProtectedRoute>
              <AccessibilityData />
            </ProtectedRoute>
            } />
          <Route path="list/:listType" element={
            <ProtectedRoute>
              <ListPage />
            </ProtectedRoute>
            } />
          <Route path="pdf/:listType/:id" element={
            <ProtectedRoute>
              <PDFPreviewPage />
            </ProtectedRoute>
            } />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>

    </Router>
    </AuthProvider>
  )
}

export default App
