/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid'
import { getDaysInMonth, daysInWeek, areDatesEqual } from '../../utils/dateUtils'
import { classNames } from '../../utils/classNames'

export default function Calendar ({ selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate }) {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [days, setDays] = useState([])
  const [isSelectingRange, setIsSelectingRange] = useState(false)

  useEffect(() => {
    setDays(getDaysInMonth(currentDate))
  }, [currentDate])

  const handleDateClick = (dateString) => {
    const clickedDate = new Date(dateString)

    if (!isSelectingRange) {
      // First click sets start date
      setSelectedStartDate(clickedDate)
      setSelectedEndDate(clickedDate)
      setIsSelectingRange(true)
    } else {
      // Second click sets end date
      const start = new Date(selectedStartDate)
      const end = new Date(clickedDate)

      if (end < start) {
        setSelectedEndDate(selectedStartDate)
        setSelectedStartDate(clickedDate)
      } else {
        setSelectedEndDate(clickedDate)
      }
      setIsSelectingRange(false)
    }
  }

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1))
  }

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1))
  }

  function isDateInRange (date, start, end) {
    if (!start || !end) return false

    // Convert all to numeric timestamps, zeroing out the hours on the day in question,
    // and letting end date be 23:59:59.999 to include the entire day
    const dateMs = new Date(date).setHours(0, 0, 0, 0)
    const startMs = new Date(start).setHours(0, 0, 0, 0)
    const endMs = new Date(end).setHours(23, 59, 59, 999)

    // Inclusive check
    return dateMs >= startMs && dateMs <= endMs
  }

  // function areDatesEqualDay (dateA, dateB) {
  //   const dA = new Date(dateA)
  //   const dB = new Date(dateB)
  //   return (
  //     dA.getFullYear() === dB.getFullYear() &&
  //     dA.getMonth() === dB.getMonth() &&
  //     dA.getDate() === dB.getDate()
  //   )
  // }

  // // True if start == end (same day)
  // function isSingleDayRange (start, end) {
  //   if (!start || !end) return false
  //   return areDatesEqualDay(start, end)
  // }

  // // True if the difference is exactly 1 day
  // // (meaning no day is "fully in between")
  // function isConsecutiveDayRange (start, end) {
  //   if (!start || !end) return false
  //   const startMs = new Date(start).setHours(0, 0, 0, 0)
  //   const endMs = new Date(end).setHours(0, 0, 0, 0)
  //   return (endMs - startMs) === 24 * 60 * 60 * 1000
  // }

  console.log('in Calendar', selectedStartDate, selectedEndDate, currentDate, isDateInRange(currentDate, selectedStartDate, selectedEndDate))

  return (
    <div className="mt-1 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          onClick={prevMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="size-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-xs font-semibold">
          {currentDate.toLocaleString('en-GB', { month: 'long', year: 'numeric' })}
        </div>
        <button
          type="button"
          onClick={nextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="size-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs/7 text-gray-500">
        {daysInWeek.map((day, idx) => (
          <div key={idx}>{day}</div>
        ))}
      </div>
      <div className="mt-1 grid grid-cols-7 gap-px rounded-lg text-xs">
        {days?.map((day) => {
          const dayDate = new Date(day.date)

          console.log('day', dayDate, day.isToday)
          return (
            <button
              key={day.date}
              type="button"
              onClick={() => handleDateClick(day.date)}
              className={classNames(
                'mx-auto flex flex-col size-8 items-center justify-center rounded-full hover:bg-gray-100 focus:z-10 relative',
                !day.isCurrentMonth && 'text-gray-400',
                day.isToday && 'text-indigo-600',
                isDateInRange(dayDate, selectedStartDate, selectedEndDate) && 'bg-gray-200'
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  'flex items-center justify-center',
                  day.isSelected && day.isToday && 'bg-indigo-600',
                  day.isSelected && !day.isToday && 'bg-gray-900'
                )}
              >
                {day.date.split('-').pop().replace(/^0/, '')}
              </time>
              <div className={classNames(
                'rounded-full w-[4.5px] h-[4.5px] absolute bottom-0.5',
                (areDatesEqual(dayDate, selectedStartDate) || areDatesEqual(dayDate, selectedEndDate)) && 'bg-violet-600'
              )}></div>
            </button>
          )
        })}
      </div>
    </div>
  )
}
