import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import Button from './Button'

export default function Pagination ({ currentPage, setCurrentPage, data }) {
  return (
    <div className="flex items-center justify-between pt-5 border-t">
    <Button logo={<ArrowLeftIcon className="h-5 w-5" />} text="Previous" className={`logo-button flex items-center font-semibold border ${
        currentPage === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'
      }`} onClick={() => setCurrentPage(prev => prev - 1)} disabled={currentPage === 0}/>
    <div className="flex items-center gap-2">
      {Array.from({ length: Math.ceil(data.length / 7) }, (_, i) => {
        const totalPages = Math.ceil(data.length / 7)

        // Mostrar primeras 3 páginas, últimas 3 páginas, página actual y adyacentes
        if (
          i < 3 || // Primeras 3 páginas
          i >= totalPages - 3 || // Últimas 3 páginas
          i === currentPage || // Página actual
          i === currentPage - 1 || // Página anterior
          i === currentPage + 1 // Página siguiente
        ) {
          return (
            <button
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`px-3 py-1 text-sm rounded-lg ${
                currentPage === i ? 'bg-light-grey text-text-dark-grey' : 'hover:bg-gray-50 text-text-mid-grey'
              }`}
            >
              {i + 1}
            </button>
          )
        } else if (
          (i === 3 && currentPage < totalPages - 4) || // Puntos después de primeras páginas
          (i === totalPages - 4 && currentPage > 2) // Puntos antes de últimas páginas
        ) {
          return <span key={i} className="px-2">...</span>
        }
        return null
      })}
    </div>    <Button logo={<ArrowRightIcon className="h-5 w-5" />} text="Next" logoPosition = 'right' disabled={currentPage >= Math.ceil(data.length / 7) - 1} onClick={() => setCurrentPage(prev => prev + 1)} className={`logo-button flex font-semibold border ${
        currentPage >= Math.ceil(data.length / 7) - 1
          ? 'opacity-50 cursor-not-allowed disabled'
          : 'hover:bg-gray-50'
      }`}/>

  </div>
  )
}
