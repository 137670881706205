import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore'
import { db, storage } from '../firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import UploadComponent from '../components/pdf/UploadComponent'
import ConfigForm from '../components/pdf/ConfigForm'
import Container from '../components/layout/container/Container'
import Button from '../components/common/Button'

const CreateReportPage = () => {
  const [jsonFiles, setJsonFiles] = useState([])
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('')
  const navigate = useNavigate()
  const formRef = useRef(null)

  const { listType } = useParams()

  const scopeKey = listType === 'projects' ? 'scope' : 'jiraId'

  const handleUpload = useCallback((files) => {
    console.log('Files uploaded:', files)
    setJsonFiles(files)
  }, [])

  const handleSubmit = useCallback(async (data) => {
    console.log('Submit handler called with data:', data)

    if (jsonFiles.length === 0) {
      console.log('No files uploaded')
      setMessage('Please upload files before generating the report.')
      setMessageType('error')
      return
    }

    // Always build config the same way:
    const config = {
      name: data.name || '',
      url: data.url || '',
      [scopeKey]: data[scopeKey] || '',
      environment: data.environment,
      timestamp: serverTimestamp()
    }

    console.log('Config being sent to Firestore:', config)

    try {
      console.log('Creating document in Firestore')
      const docRef = await addDoc(collection(db, `accessibility-${listType}`), { config })
      const dataId = docRef.id
      console.log('Document created with ID:', dataId)

      const updatedWebpages = []

      for (const file of jsonFiles) {
        console.log('Processing file:', file.name)
        const path = `${listType}/${dataId}/${file.name}`
        const storageRef = ref(storage, path)
        await uploadBytes(storageRef, file.content)
        const downloadURL = await getDownloadURL(storageRef)
        console.log('File uploaded, download URL:', downloadURL)

        updatedWebpages.push({
          name: file.name,
          path,
          webPath: file.webPath,
          url: downloadURL,
          environment: data.environment
        })
      }

      console.log('Updating Firestore document')
      await updateDoc(doc(db, `accessibility-${listType}`, dataId), {
        config: { ...config, webpages: updatedWebpages }
      })

      console.log('Document successfully updated')
      setMessage('Data successfully sent to Firebase!')
      setMessageType('success')
    } catch (e) {
      console.error('Error adding document: ', e)
      setMessage('Error sending data to Firebase.')
      setMessageType('error')
    }
  }, [jsonFiles, listType, scopeKey])

  const handleGenerateReport = () => {
    const name = formRef.current?.querySelector('[name="name"]')?.value
    const url = formRef.current?.querySelector('[name="url"]')?.value
    const scopeValue = formRef.current?.querySelector(`[name="${scopeKey}"]`)?.value
    const environment = formRef.current?.querySelector('[name="environment"]:checked')?.value

    console.log('environment', environment)

    if (!name || !url || !scopeValue) {
      setMessage('Please fill in all required fields.')
      setMessageType('error')
      return
    }
    handleSubmit({
      name,
      url,
      [scopeKey]: scopeValue,
      environment
    })
  }

  return (
    <Container>
      <div>
        <div className="flex flex-wrap pb-4 items-center justify-between sm:flex-nowrap">
          <div>
            <h1 className="text-2xl font-bold">Create new App Analysis</h1>
            <p className="text-lg text-gray-600">
              New report from Json
            </p>
          </div>
        </div>
        <hr className="border-t border-gray-200 mb-0" />
      </div>
      <div ref={formRef}>
        <div className="pt-0">
          <ConfigForm isDisabled={jsonFiles.length === 0} listType={listType}/>
          {message && <p className={messageType}>{message}</p>}
          <UploadComponent onUpload={handleUpload} />
        </div>
        <div className="flex items-center justify-end gap-x-3 mr-6 pb-2 pt-4">
          <Button text="Cancel" className="default-button" onClick={() => navigate('/list-projects')} logo={null}/>
          <Button text="Generate report" className="default-button disabled:opacity-50 disabled:cursor-not-allowed" onClick={handleGenerateReport} logo={null} disabled={jsonFiles.length === 0}/>
        </div>
      </div>
    </Container>
  )
}

export default CreateReportPage
