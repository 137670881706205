import React from 'react'
import { CircleHalfLineImg } from '../../img/CircleHalfLineImg'
import { FourCirclesSquareImg } from '../../img/FourCirclesSquareImg'
import { SquareHalfLineImg } from '../../img/SquareHalfLineImg'
import { ThreeSquaresImg } from '../../img/ThreeSquaresImg'
import { TiltedPaperBoatImg } from '../../img/TitledPaperBoatImg'
import styles from './introThree.module.css'
import { HavasGroupImg } from '../../img/HavasGroupImg'

export const IntroThreeComponent = ({ projectData }) => {
  if (!projectData) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles['HAV-web']}>
      <div className={styles.overlap}>
        <p className={styles['text-wrapper']}>
          Our approach interprets Lighthouse scores into actionable insights, with detailed reporting that breaks down
          accessibility barriers and solutions for an inclusive web experience.
        </p>
        <div className={styles.frame}>
          <div className={styles.div}>
            <ThreeSquaresImg className="icon-instance-node" />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper-2']}>Color Contrast</div>
              <div className={styles.group}>
                <p className={styles.p}>Ensuring text is readable for those with visual impairments.</p>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <SquareHalfLineImg className="icon-instance-node" />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper-2']}>Alt Text for Images</div>
              <div className={styles.group}>
                <p className={styles.p}>Providing descriptions for screen reader users.</p>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <CircleHalfLineImg className="icon-instance-node" />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper-2']}>Heading Structure</div>
              <div className={styles.group}>
                <p className={styles.p}>Using headings to guide users through the site&apos;s content.</p>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <FourCirclesSquareImg />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper-2']}>Keyboard Navigation</div>
              <div className={styles.group}>
                <p className={styles.p}>Ensuring all functionalities are accessible via keyboard.</p>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <TiltedPaperBoatImg className="icon-instance-node" />
            <div className={styles['frame-3']}>
              <div className={styles['text-wrapper-2']}>ARIA Roles and Labels</div>
              <div className={styles.group}>
                <p className={styles.p}>Enhancing the accessibility of dynamic content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['overlap-2']}>
        <p className={styles['text-wrapper-3']}>
          Lighthouse provides an all-encompassing audit of web accessibility against the Web Content Accessibility
          Guidelines (WCAG).
        </p>
        <p className={styles['text-wrapper-4']}>
          These audits align with the European Accessibility Act (EAA) standards, highlighting compliance and areas for
          enhancement
        </p>
        <div className={styles['frame-4']}>
          <div className={styles['text-wrapper-5']}>
            <div className={styles['frame-6']}>
              <HavasGroupImg className={styles['group-2']} alt="Group" />
              <div className={styles['text-wrapper-5']}>Global Client Technology</div>
            </div>
            <div className={styles.comprehensive}>
              Comprehensive <br />
              Accessibility Auditing
            </div>
          </div>
          <div className={styles['frame-7']}>
            <div className={styles['frame-8']}>
              <div className={styles['frame-6']}>
                <div className={styles['text-wrapper-6']}>Project</div>
                <div className={styles['text-wrapper-7']}>{projectData.name}</div>
              </div>
              <div className={styles['frame-6']}>
                <div className={styles['text-wrapper-6']}>Scope</div>
                <div className={styles['text-wrapper-7']}>{projectData.scope}</div>
              </div>
            </div>
            <div className={styles['web-accessibility']}>
              Web <br />
              accessibility <br />
              analysis
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
