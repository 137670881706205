import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Calendar from './Calendar'
import { handleDateChange, dateOptions, parseDDMMYYYY, formatDateToDDMMYYYY } from '../../utils/dateUtils'
import { classNames } from '../../utils/classNames'
import Button from './Button'

export default function CalendarCard ({ startDate, setStartDate, endDate, setEndDate, setIsCalendarOpen }) {
  // eslint-disable-next-line no-unused-vars
  const [dateOption, setDateOption] = useState('All time')

  console.log('dates test CalendarCard', startDate, endDate)

  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [inputStartValue, setInputStartValue] = useState('')
  const [inputEndValue, setInputEndValue] = useState('')

  const handleSidebarDateChange = (dateOption) => {
    handleDateChange(dateOption, setSelectedStartDate, setSelectedEndDate)
  }

  console.log('in CalendarCard', 'selectedStartDate', formatDateToDDMMYYYY(selectedStartDate), 'selectedEndDate', formatDateToDDMMYYYY(selectedEndDate))

  useEffect(() => {
    setInputStartValue(selectedStartDate ? formatDateToDDMMYYYY(selectedStartDate) : '')
  }, [selectedStartDate])

  useEffect(() => {
    setInputEndValue(selectedEndDate ? formatDateToDDMMYYYY(selectedEndDate) : '')
  }, [selectedEndDate])

  const handleApply = () => {
    setStartDate(selectedStartDate)
    setEndDate(selectedEndDate)
  }

  return (
    <div className="bg-white rounded-lg w-[500px] pt-4 pl-4">
      <div className="flex">
        <div className="w-36 pr-2 border-r">
          <ul className="space-y-2 text-gray-700 text-xs">
            {dateOptions.map((option, idx) => (
            <li
            key={idx}
            className={classNames(
              'cursor-pointer rounded-md px-2 py-1',
              option === dateOption ? 'bg-gray-100 text-black font-semibold' : 'hover:bg-gray-100 text-gray-700'
            )}
            onClick={() => { setDateOption(option); handleSidebarDateChange(option) }}
          >
            {option}
          </li>

            ))}
          </ul>
        </div>

        <div className="flex-1">
          <div className="border-b">
            <div className="p-4 border-l border-gray-200 h-[300px]">
              <Calendar
              selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate}
              setSelectedStartDate={setSelectedStartDate} setSelectedEndDate={setSelectedEndDate}/>
            </div>
          </div>
          {/* Inputs */}
          <div className="flex space-x-4 mb-4 mx-2">
            <div className="flex mt-4 space-x-[6px] justify-start">
            <input
              type="text"
              placeholder="Start date"
              value={inputStartValue}
              onChange={(e) => setInputStartValue(e.target.value)} // Just update input state
              onBlur={() => {
                const parsedDate = parseDDMMYYYY(inputStartValue)
                if (parsedDate && !isNaN(parsedDate.getTime())) {
                  setSelectedStartDate(parsedDate)
                } else {
                  // If invalid, decide how to handle it. Maybe revert or leave as is.
                }
              }}
              className="w-20 p-2 rounded-md border border-gray-300 text-xs"
            />
            <input
              type="text"
              placeholder="End date"
              value={inputEndValue}
              onChange={(e) => setInputEndValue(e.target.value)} // Just update input state
              onBlur={() => {
                const parsedDate = parseDDMMYYYY(inputEndValue)
                if (parsedDate && !isNaN(parsedDate.getTime())) {
                  setSelectedEndDate(parsedDate)
                } else {
                  // If invalid, decide how to handle it. Maybe revert or leave as is.
                }
              }}
              className="w-20 p-2 rounded-md border border-gray-300 text-xs"
            />
          </div>
          {/* Action Buttons */}
          <div className="flex justify-end mt-4 space-x-[6px]">
            <button className="px-4 py-2 bg-gray-200 text-gray-700 text-xs font-semibold rounded-md hover:bg-gray-300" onClick={() => setIsCalendarOpen((prev) => !prev)}>
              Cancel
            </button>
            <Button className="default-button text-xs " onClick={handleApply} logo={null} text="Apply" />
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CalendarCard.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setEndDate: PropTypes.func.isRequired,
  setIsCalendarOpen: PropTypes.func.isRequired
}
