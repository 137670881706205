import React from 'react'
import { AsteriskSquareImg } from '../../img/AsteriskSquareImg'
import { HalfCirclesImg } from '../../img/HalfCirclesImg'
import { SquareLineImg } from '../../img/SquareLineImg'
import { SquareSticksImg } from '../../img/SquareSticks'
import styles from './introFour.module.css'
import { HavasGroupImg } from '../../img/HavasGroupImg'

export const IntroFourComponent = ({ projectData }) => {
  if (!projectData) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles['HAV-web']}>
      <div className={styles['overlap-group']}>
        <div className={styles.frame}>
          <div className={styles.div}>
            <SquareLineImg className={styles.element} />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper']}>Purpose of the EAA</div>
              <div className={styles.group}>
                <div className="flexcontainer">
                  <p className="text">
                    <span className="span">
                      To ensure that essential products and services are accessible to individuals with disabilities.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="span">
                      To harmonize accessibility standards across the European market, enhancing the social inclusion
                      and independence of people with disabilities.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <SquareSticksImg className={styles.element} />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper']}>Impact on Web Accessibility</div>
              <div className="flexcontainer-wrapper">
                <div className="flexcontainer-2">
                  <p className="text">
                    <span className="span">
                      The Act covers digital products and services, including websites and mobile applications.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="span">
                      Requires compliance with common EU standards, which are largely based on the WCAG.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <HalfCirclesImg className={styles.element} />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper']}>Compliance with the EAA</div>
              <div className="flexcontainer-wrapper">
                <div className="flexcontainer-2">
                  <p className="text">
                    <span className="span">
                      Businesses and organizations must ensure their digital content is perceivable, operable,
                      understandable, and robust.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="span">
                      Failure to comply can result in legal consequences and restrict access to the European market.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div}>
            <AsteriskSquareImg className={styles.element} />
            <div className={styles['frame-2']}>
              <div className={styles['text-wrapper']}>Our Commitment</div>
              <div className={styles['div-wrapper']}>
                <p className={styles.p}>
                  We are dedicated to ensuring that all our digital solutions are fully compliant with the EAA,
                  providing equal access and opportunity for all users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overlap}>
        <p className={styles['text-wrapper-2']}>
          The EAA is a legislative initiative aimed at enforcing accessibility standards across EU member states.
        </p>
        <div className={styles['frame-3']}>
          <div className={styles['frame-4']}>
            <div className={styles['frame-5']}>
              <HavasGroupImg className={styles.img} alt="Group" />
              <div className={styles['text-wrapper-3']}>Global Client Technology</div>
            </div>
            <p className={styles['text-wrapper-4']}>Understanding the European Accessibility Act (EAA)</p>
          </div>
          <div className={styles['frame-6']}>
            <div className={styles['frame-7']}>
              <div className={styles['frame-5']}>
                <div className={styles['text-wrapper-5']}>Project</div>
                <div className={styles['text-wrapper-6']}>{projectData.name}</div>
              </div>
              <div className="frame-5">
                <div className={styles['text-wrapper-5']}>Scope</div>
                <div className={styles['text-wrapper-6']}>{projectData.scope}</div>
              </div>
            </div>
            <div className={styles['web-accessibility']}>
              Web <br />
              accessibility <br />
              analysis
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
