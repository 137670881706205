import React from 'react'
import styles from './cardsData.module.css'
import { Card } from '../../shared/Card'
import { SidebarUrlTest } from '../../shared/SidebarUrlTest'
import { CardNotAppl } from '../../shared/CardNotAppl'

export const CardsDataComponent = ({ status, project, baseURL, path, errorArray, passedArray, notApplArray, scope }) => {
  let typeClassName
  let statusText
  let dataToIterate
  let slicedPassedArray
  let slicedErrorArray
  let slicedNotApplArray

  // Slice arrays to ensure they do not exceed their maximum lengths
  const maxErrorArrayLength = 16
  const maxPassedArrayLength = 16
  const maxNotApplArrayLength = 36

  switch (status) {
    case 'PASSED':
      typeClassName = 'type-passed'
      statusText = 'PASSED AUDITS'
      slicedPassedArray = passedArray.slice(0, maxPassedArrayLength)
      dataToIterate = slicedPassedArray
      break
    case 'ERROR':
      typeClassName = 'type-error'
      statusText = 'NEEDS CHANGES'
      slicedErrorArray = errorArray.slice(0, maxErrorArrayLength)
      dataToIterate = slicedErrorArray
      break
    case 'NOT':
      typeClassName = 'type-not'
      statusText = 'NOT APPLICABLE'
      slicedNotApplArray = notApplArray.slice(0, maxNotApplArrayLength)
      dataToIterate = slicedNotApplArray
      break
    default:
      typeClassName = ''
      statusText = ''
      dataToIterate = []
  }

  // Dynamically set class for the container based on status
  const containerCardsClass = `${styles['container-cards']} ${status === 'NOT' ? styles['container-cards-gap-40'] : ''}`

  return (
    <div className={styles['HAV-web']}>
      <SidebarUrlTest
        className={styles['sidebar-url-test-instance']}
        project={project}
        baseUrl={baseURL}
        path={path}
        scope={scope}
      />
      <div className={styles['overlap-group']}>
        <header className={styles.header}>
          <div className={styles['title-cards']}>
            <p className={styles.p}>Analyzed opportunities to improve the accessibility</p>
          </div>
          <div className={`${styles.type} ${styles[typeClassName]}`}>
            <div className={styles['text-wrapper-status']}>{statusText}</div>
          </div>
        </header>
        <div className={containerCardsClass}>
          {status === 'NOT'
            ? dataToIterate.map((item, index) => (
               <CardNotAppl
                 key={`${item.id}-${index}`} // Use a combination of item id and index for a unique key
                 title={item.title} // Only pass the title as per requirement
                 spanClassName={styles['card-instance']}
                 description={item.description}
               />
            ))
            : dataToIterate.map((item, index) => (
               <Card
                 key={`${item.id}-${index}`}
                 spanClassName={styles['card-instance']}
                 status={status}
                 title={item.title}
                 description={item.description}
                 url="url-2.svg" // Adjust as needed, could be `item.url` if applicable
               />
            ))
          }
        </div>
      </div>
    </div>
  )
}
